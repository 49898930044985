<template>
    <Main :qrcode="true">
        <Header checked="introduction"></Header>
        <Banner></Banner>
        <div class="background">
            <Block>
                <div class="middle-block">
                    <intro :lang="lang"></intro>
                    <scale></scale>
                    <services :lang="lang"></services>
                    <event :lang="lang"></event>
                    <hight-lights :lang="lang"></hight-lights>
                    <other :lang="lang"></other>
                </div>
            </Block>
        </div>
        <Footer></Footer>
    </Main>
</template>

<script>
import Main from '@/components/layout2/layout2-main.vue';
import Header from '@/components/layout2/layout2-header.vue';
import Footer from '@/components/layout2/layout2-footer.vue';
import Banner from '@/components/layout2/layout2-banner.vue';
import Block from '@/components/layout2/layout2-block.vue'
import Intro from './components/intro.vue'
import Scale from './components/scale.vue'
import Services from './components/services.vue'
import Event from './components/event.vue'
import HightLights from './components/hightlights.vue'
import Other from './components/other.vue'
import { getLanguage }from '@/lang/index'

export default {
    name: 'introduction',
    components: {
        Main,
        Header,
        Footer,
        Banner,
        Block,
        Intro,
        Scale,
        Services,
        Event,
        HightLights,
        Other
    },
    data() {
        return {
            lang: 'zh'
        }
    },
    mounted() {
        document.title = this.$t("common.title8");
        this.lang = getLanguage()
    }
}
</script>

<style lang="less" scoped>
.middle-block{
    // width: 1168px;
    // margin: 0 auto;
    padding-bottom: 70px;
}
</style>
<template>
    <div class="scale-section">
      <div class="middle-title">展会规模</div>
      <div class="middle-subtitle">EXHIBITION SCALE</div>
      <div class="scale-con">
          <!-- <div class="scale-item">
              <div class="scale-item-content">
                  <img :src="require('@/assets/img/introduction/scale.png')" alt="" class="scale-icon">
                  <div class="scale-area">80,000m²</div>
                  <div class="scale-title">展览规模</div>
                  <div class="scale-subtitle">EXHIBITION SCALE</div>
              </div>
          </div> -->
          <div class="scale-item">
              <div class="scale-item-content">
                  <img :src="require('@/assets/img/introduction/venue.png')" alt="" class="scale-icon" style="margin-left: 12px;">
                  <div class="scale-area">50,000m²</div>
                  <div class="scale-title">主会场展览规模</div>
                  <div class="scale-subtitle">EXHIBITION SCALE OF MAIN VENUE</div>
              </div>
          </div>
          <div class="scale-item">
              <div class="scale-item-content">
                  <img :src="require('@/assets/img/introduction/exhbitors.png')" alt="" class="scale-icon" style="margin-left: -20px;">
                  <div class="scale-area">2,000+</div>
                  <div class="scale-title">参展企业数</div>
                  <div class="scale-subtitle">EXHIBITORS</div>
              </div>
          </div>
          <div class="scale-item">
              <div class="scale-item-content">
                  <img :src="require('@/assets/img/introduction/visitors.png')" alt="" class="scale-icon" style="margin-left: -13px;">
                  <div class="scale-area">80,000+</div>
                  <div class="scale-title">参观人次</div>
                  <div class="scale-subtitle">VISITORS</div>
              </div>
          </div>
          <div class="scale-item">
              <div class="scale-item-content">
                  <img :src="require('@/assets/img/introduction/attrndance.png')" alt="" class="scale-icon" style="margin-left: -2px;">
                  <div class="scale-area">300,000+</div>
                  <div class="scale-title">线上观展人数</div>
                  <div class="scale-subtitle">ONLINE ATTENDANCE</div>
              </div>
          </div>
      </div>
    </div>
</template>

<script>
export default {
}
</script>

<style lang="less" scoped>
.middle-title{
    width: 100%;
    padding-top: 50px;
    font-size: 30px;
    font-weight: bold;
    color: #333333;
    text-align: center;
}
.middle-subtitle{
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #999999;
    text-align: center;
}
.scale-con{
    display: flex;
    // justify-content: space-between;
    flex-shrink: 0;
}
.scale-item{
    // width: 240px;
    display: flex;
    flex-direction: column;
    // box-sizing: border-box;
    &:first-child{
        width: 195px;
        margin-left: 120px;
    }
    &:nth-child(2){
        width: 93px;
        margin-left: 125px;
    }
    &:nth-child(3){
        width: 109px;
        margin-left: 159px;
    }
    &:nth-child(4){
        width: 130px;
        margin-left: 156px;
    }
}
.scale-item-content{
    display: flex;
    flex-direction: column;
}
.scale-icon{
    width: 122px;
    height: 126px;
}
.scale-area{
    position: relative;
    font-size: 30px;
    font-family: Arial;
    font-weight: bold;
    color: #CF010E;
}
.scale-area:after{
    content: "";
    width: 36px;
    height: 4px;
    position: absolute;
    bottom: -6px;
    left: 0;
    background-color: #707070;
}
.scale-title{
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
    color: #666666;
}
.scale-subtitle{
    margin-top: 6px;
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    white-space: nowrap;
}
</style>
<template>
  <div class="merge-section">
    <div class="exhibits-section">
        <div class="merge-title">
            <div class="merge-title-ch">展品类型</div>
            <div class="merge-title-en">EXHIBITS</div>
        </div>
        <div class="exhibits-con" v-if="lang == 'zh'">
            <div class="merge-list-left">正版授权玩具、模型玩具</div>
            <div class="merge-list-left">婴幼童玩具、娃娃、布毛绒玩具</div>
            <div class="merge-list-left">户外即运动用品、体育玩具、节日、派对用品</div>
            <div class="merge-list-left">益智科教、积木、拼插玩具、DIY玩具、纸质类玩具</div>
            <div class="merge-list-left">遥控玩具、电动玩具、塑胶玩具</div>
            <div class="merge-list-left">IP授权 、设计服务、设备原料、包装配件等</div>
        </div>
        <div class="exhibits-con" v-else style="height: 435px">
            <div class="merge-list-left">Genuine authorized toys, model toys</div>
            <div class="merge-list-left">Baby toys, Dolls, Cloth and stuffed toys </div>
            <div class="merge-list-left">Outdoor and Sporting goods, Sports toys, Program & Party Supplies </div>
            <div class="merge-list-left">Educational toys, Building blocks, Interlocking toys, DIY toys, Paper toys </div>
            <div class="merge-list-left">Remote-control toys, Electric toys, Plastic toys </div>
            <div class="merge-list-left">IP authorization, Design services, Equipment raw materials, Packaging accessories</div>
        </div>
    </div>
    <div class="merge-section-right">
        <div class="schedule-section">
            <div class="merge-title">
                <div class="merge-title-ch">展会日程</div>
                <div class="merge-title-en">EXHIBITION SCHEDULE</div>
            </div>
            <div class="schedule-con" v-if="lang == 'zh'">
                <div class="merge-list-left">
                    <div style="width: 157px">2023年4月1-2日</div>
                    <div style="margin-left: 37px;">[ 9:00am - 17:00 pm ]</div>
                </div>
                <div class="merge-list-left">
                    <div style="width: 157px">2023年4月3日</div>
                    <div style="margin-left: 37px;">[ 9:00am - 15:00 pm ]</div>
                </div>
            </div>
             <div class="schedule-con" v-else>
                <div class="merge-list-left">
                    <div style="width: 157px">April 1-2, 2023</div>
                    <div style="margin-left: 37px;">[ 9:00am - 17:00 pm ]</div>
                </div>
                <div class="merge-list-left">
                    <div style="width: 157px">April 3, 2023</div>
                    <div style="margin-left: 37px;">[ 9:00am - 15:00 pm ]</div>
                </div>
            </div>
        </div>
        <div class="fee-section">
            <div class="merge-title">
                <div class="merge-title-ch">参展费用</div>
                <div class="merge-title-en">PARTICITATION FEE</div>
            </div>
            <div class="fee-con" v-if="lang == 'zh'">
                <div class="merge-list-left">
                    <div style="width: 72px">展位规格</div>
                    <div style="margin-left: 36px;width: 170px;">标准展位（9m²）</div>
                    <div style="margin-left: 41px;">光地展位（最少36m²）</div>
                </div>
                <div class="merge-list-left">
                    <div style="width: 72px">展位价格</div>
                    <div style="margin-left: 36px;width: 170px;" class="fee-money-left">RMB9800 元/ 9m²</div>
                    <div style="margin-left: 41px;width: 190px;" class="fee-money-right">RMB900 元/ 9m²</div>
                </div>
            </div>
            <div class="fee-con" v-else>
                <div class="merge-list-left"  style="margin-bottom: 36px">
                    <div style="width: 174px">Booth specifications</div>
                    <div style="margin-left: 12px;width: 170px;">standard（9m²）</div>
                    <div style="margin-left: 12px;width:120px" class="specifications">Bare land</div>
                </div>
                <div class="merge-list-left">
                    <div style="width: 174px">Booth price</div>
                    <div style="margin-left: 12px;width: 149px;">RMB 9800 / 9m ²</div>
                    <div style="margin-left: 26px;width: 190px;">RMB 900 / 9m²</div>
                </div>
            </div>
        </div>
        <div class="merge-remark"  v-if="lang == 'zh'">备注：9m²标准展位设施：1个咨询台，2把折椅，3个长臂射灯，9块平板层，中英文楣板，地毯，1个废
纸篓，1个500Q/220V（5A）电源插座</div>
        <div class="merge-remark-ch" v-else>Remarks: 9m ² Standard booth facilities: 1 information desk, 2 folding chairs, 3 long arm spotlights, 
9 flat floors, Chinese and English lintels, carpets and 1 wasteWastebasket, 1 500q / 220V (5a) power
socket</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    lang: {
      type: String,
      defalut: 'zh'
    }
  }
}
</script>

<style lang="less" scoped>
.middle-title{
    width: 100%;
    padding-top: 60px;
    font-size: 30px;
    font-weight: bold;
    color: #333333;
    text-align: center;
}
.middle-subtitle{
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #999999;
    text-align: center;
}
.merge-section{
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
}
.merge-title{
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;
}
.merge-title-ch{
    margin-right: 14px;
    font-size: 30px;
    font-weight: bold;
    color: #333333;
}
.merge-title-en{
    font-size: 24px;
    font-weight: bold;
    color: #999999;
}
.exhibits-con{
    width: 580px;
    height: 413px;
    padding: 30px 20px;
    background: #F6F7F7;
    border-radius: 10px;
    box-sizing: border-box;
}
.merge-list-left{
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    position: relative;
    padding-left: 24px;
}
.exhibits-con > .merge-list-left:not(:last-child),.schedule-con > .merge-list-left:not(:last-child),.fee-con > .merge-list-left:not(:last-child) {
    margin-bottom: 26px;
}
.merge-list-left::before{
    content: "";
    width: 12px;
    height: 12px;
    position: absolute;
    left: 0;
    top: 5px;
    border-radius: 50%;
    background-color: #3053A3;
}
.merge-section-right{
    width: 600px;
}
.schedule-con{
    width: 100%;
    height: 134px;
    padding: 30px 20px;
    background: #F6F7F7;
    border-radius: 10px;
    box-sizing: border-box;
}
.fee-section{
    margin-top: 20px;
}
.fee-con{
    width: 100%;
    height: 157px;
    padding: 30px 0 30px 20px;
    background: #F6F7F7;
    border-radius: 10px;
    box-sizing: border-box;
}
.fee-money-left, .fee-money-right,.specifications{
    position: relative;
}
.fee-money-left::after{
    content: "（拐角位加收附加费10%）";
    position: absolute;
    bottom: -20px;
    left: -8px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
}
.fee-money-right::after{
    content: "（展馆另收特装管理费）";
    position: absolute;
    bottom: -20px;
    left: -8px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
}
.specifications::after{
  content: "（at least 36m²）";
  position: absolute;
  bottom: -20px;
  left: -8px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}
.merge-remark{
    margin-top: 6px;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #999999;
}
.merge-remark-ch{
  margin-top: 12px;
  font-size: 13px;
  color: #999999;
}
</style>
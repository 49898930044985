<template>
  <div class="services-section">
    <div class="middle-title">展会亮点</div>
    <div class="middle-subtitle">FAIR HIGHLIGHTS</div>
    <div class="fir-con" v-if="lang == 'zh'">
        <div class="fir-item">
            <div class="fir-item-top">
                <div class="fir-item-top-left">M</div>
                <div class="fir-item-top-right">
                    <div class="fir-top-en">ULTIVENUE</div>
                    <div class="fir-top-ch">多会场</div>
                </div>
            </div>
            <div class="fir-item-intro">通过“主会场+分会场”相结合，与宏腾展厅、澄海科创中心、宝奥城、新悦翔玩具展馆、耀昇展厅等联动展示，聚集多方资源，共同打造世界级展会。</div>
        </div>
        <div class="fir-item">
            <div class="fir-item-top">
                <div class="fir-item-top-left">O</div>
                <div class="fir-item-top-right">
                    <div class="fir-top-en">NLINE EXHIBITION</div>
                    <div class="fir-top-ch">云展会</div>
                </div>
            </div>
            <div class="fir-item-intro">利用“互联网+展会”的新模式，搭建2023线上玩博会官方平台，同时联动澄海多个玩具行业线上平台共同推动构建云展会新时代。</div>
        </div>
        <div class="fir-item">
            <div class="fir-item-top">
                <div class="fir-item-top-left">N</div>
                <div class="fir-item-top-right">
                    <div class="fir-top-en">EW CHANNELS</div>
                    <div class="fir-top-ch">新渠道</div>
                </div>
            </div>
            <div class="fir-item-intro">线上线下赋能+多平台资源融合，通过数字化驱动、跨境电商助力，利用直播电商的高转化率实现客流+销量快速增长，为企业赋能，拓展新渠道。</div>
        </div>
        <div class="fir-item">
            <div class="fir-item-top">
                <div class="fir-item-top-left">F</div>
                <div class="fir-item-top-right">
                    <div class="fir-top-en">IXED INVITATION</div>
                    <div class="fir-top-ch">定向邀约</div>
                </div>
            </div>
            <div class="fir-item-intro">澄海玩具协会是中国最具影响力的行业组织之一，引领澄海玩具企业发展方向；承办单位服务玩具行业16年已沉淀积累20万+专业观众资源。</div>
        </div>
        <div class="fir-item">
            <div class="fir-item-top">
                <div class="fir-item-top-left">T</div>
                <div class="fir-item-top-right">
                    <div class="fir-top-en">OY MAGNATES GATHERING</div>
                    <div class="fir-top-ch">大咖云集</div>
                </div>
            </div>
            <div class="fir-item-intro">1+5主题论坛分享，邀请行业大咖举办多场专业主题论坛，共探行业趋势，引领行业发展。</div>
        </div>
        <div class="fir-item">
            <div class="fir-item-top">
                <div class="fir-item-top-left">G</div>
                <div class="fir-item-top-right">
                    <div class="fir-top-en">REEN EXHIGITIONI</div>
                    <div class="fir-top-ch">绿色展览</div>
                </div>
            </div>
            <div class="fir-item-intro">秉承绿色办展的理念，倡议绿色搭建，搭建材料模块化、标准化，采用环保、可循环利用材料代替传统展材。</div>
        </div>
    </div>
    <div class="fir-con" v-else>
        <div class="fir-item">
            <div class="fir-item-top">
                <div class="fir-item-top-left">M</div>
                <div class="fir-item-top-right">
                    <div class="fir-top-en">ULTIVENUE</div>
                    <div class="fir-top-ch">多会场</div>
                </div>
            </div>
            <div class="fir-item-intro">Through combining “main venue + branch venues”, the expo will be displayed in conjunction with HOTON Toys Showroom, Chenghai Sci-tech 
Innovation Center, P.A.CITY, ON TOP Toys Exhibition Hall, YS WIN-WIN Exhibition Hall, etc., through gathering multiple resources, a world-class 
exhibition will be jointly created.</div>
        </div>
        <div class="fir-item">
            <div class="fir-item-top">
                <div class="fir-item-top-left">O</div>
                <div class="fir-item-top-right">
                    <div class="fir-top-en">NLINE EXHIBITION</div>
                    <div class="fir-top-ch">云展会</div>
                </div>
            </div>
            <div class="fir-item-intro">By deploying the new model of “Internet + Exhibition”, an official online platform of 2023 Toy Expo (Online) will be built, meanwhile, through 
cooperating with a variety of online platforms in the toy industry in Chenghai, such as toysaas.com, toysbao.com, and toybaba.com, etc. a new era of 
online exhibition will be opened.</div>
        </div>
        <div class="fir-item">
            <div class="fir-item-top">
                <div class="fir-item-top-left">N</div>
                <div class="fir-item-top-right">
                    <div class="fir-top-en">EW CHANNELS</div>
                    <div class="fir-top-ch">新渠道</div>
                </div>
            </div>
            <div class="fir-item-intro">Online and offline empowerment & multi-platform resource integration, driven by digitalization with the assistance of cross-border e-commerce, 
rapid growth in customer flow & sales can be achieved through high conversion rate of live e-commerce, so as to empower enterprises and expand 
new channels for them.</div>
        </div>
        <div class="fir-item">
            <div class="fir-item-top">
                <div class="fir-item-top-left">F</div>
                <div class="fir-item-top-right">
                    <div class="fir-top-en">IXED INVITATION</div>
                    <div class="fir-top-ch">定向邀约</div>
                </div>
            </div>
            <div class="fir-item-intro">As one of the most influential industry organizations in China, Chenghai Toys Association leads the development of Chenghai toy enterprises; the 
organizers have accumulated 200,000+ professional visitor resources through serving the toy industry for 16 years.</div>
        </div>
        <div class="fir-item">
            <div class="fir-item-top">
                <div class="fir-item-top-left">T</div>
                <div class="fir-item-top-right">
                    <div class="fir-top-en">OY MAGNATES GATHERING</div>
                    <div class="fir-top-ch">大咖云集</div>
                </div>
            </div>
            <div class="fir-item-intro">1+5 summit forums, a number of professional summit forums with industry leaders sharing insights to explore industry trends and lead industry 
development.</div>
        </div>
        <div class="fir-item">
            <div class="fir-item-top">
                <div class="fir-item-top-left">G</div>
                <div class="fir-item-top-right">
                    <div class="fir-top-en">REEN EXHIGITIONI</div>
                    <div class="fir-top-ch">绿色展览</div>
                </div>
            </div>
            <div class="fir-item-intro">Adhering to the concept of green exhibition and advocating green construction, the expo aims at modularization and standardization of construction 
materials by using environmentally friendly and recyclable materials instead of traditional exhibition materials.</div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    lang: {
      type: String,
      defalut: 'zh'
    }
  }
}
</script>

<style lang="less" scoped>
.middle-title{
    width: 100%;
    padding-top: 50px;
    font-size: 30px;
    font-weight: bold;
    color: #333333;
    text-align: center;
}
.middle-subtitle{
    width: 100%;
    margin-top: 10px;
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: bold;
    color: #999999;
    text-align: center;
}
.fir-con{
  border: 1px solid #3053A3;
}
.fir-item{
    padding: 8px 30px 19px 20px;
}
.fir-item:nth-child(odd){
    background-color: #F6F7F7;
}
.fir-item-top{
    display: flex;
}
.fir-item-top-left{
    width: 72px;
    font-size: 70px;
    font-weight: bold;
    color: #3053A3;
    text-align: center;
}
.fir-top-en{
    margin-top: 17px;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
}
.fir-top-ch{
    font-size: 30px;
    font-weight: bold;
    color: #333333;
}
.fir-item-intro{
  font-size: 16px;
  font-weight: 400;
  color: #333333;
}
</style>
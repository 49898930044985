<template>
  <div class="introduction-section">
    <div class="middle-title">展会规模</div>
    <div class="middle-subtitle">EXHIBITION SCALE</div>
    <div class="introduction-contain">
        <div class="introduction-left" v-if="lang == 'zh'">
            <div class="introduction-title">第22届中国澄海国际玩具礼品博览会暨2023线上玩博会</div>
            <div>
                <div class="introduction-con">第22届中国澄海国际玩具礼品博览会暨2023线上玩博会以“玩具之都燃动全球”
                    为主题,秉承“高质量、高关注度、高曝光率、高专业性、高国际化”的办展理念。展
                    会通过“主会场+分会场+云展会”的创新模式，搭建2023线上玩博会官方平台,并举
                    办系列主题论坛，推动玩具产业转型升级和快速发展。
                </div>
                <div class="introduction-con">工业是经济发展的基石，是推动城市发展的主动力，产业是区域经济发展的关键
                    所在。市委、市政府高位谋划推动“工业立市、产业强市”,构建汕头独有的“三新两
                    特一大”产业发展新格局。其中,“两特”之-就是玩具创意，主办方澄海玩具协会将
                    邀请全国30多家战略合作协会组团参展观展，充分发挥澄海玩具基地产业集群的优势，
                    为玩具企业搭建一个集“品牌展示产品交易新品发布”为一体的行业交流与合作交易
                    平台，助推澄海玩具产业高质量发展。
                </div>
            </div>
        </div>
        <div class="introduction-left-ch" v-else>
            <div class="introduction-title-ch">Introduction: THE 22nd CHINA CHENGHAI INTERNATIONAL TOYS & GIFTS FAIR ONLINE & OFFLINE</div>
            <div>
                <div class="introduction-con-ch">Taking “Toy City: Light up the World” as its theme and adhering to the concept of organizing an eye-catching Expo in a professional and international manner with quality, THE 22nd CHINA CHENGHAI INTERNATIONAL TOYS & GIFTS FAIR, ONLINE & OFFLINE builds an official online platform while holding a series of forums through an innovative form of “Exhibition Hall + Joint Exhibition Unit + Online Toy Expo”, so as to promote transformation, upgrading and rapid development of the toy industry.</div>
                <div class="introduction-con-ch">Industry is not only the cornerstone of economic development and the main driving force for urban development, but also the key to regional economic development. The municipal party committee and municipal government of Shantou have made strategic planning to promote the idea of “Building and strengthening the city through industrial development” to create a new industrial development dynamic with its unique features of three new industries, two characteristic traditional industries and one big health industry. Among which two characteristic traditional industries include toy & creation industry; Chenghai Toys Association, as the sponsor, will invite 30+ strategic cooperation associations across the country to form a group to participate in the exhibition; by giving full play to the advantages of the Chenghai toy base industrial cluster, the expo will build an industry exchange and cooperation trading platform for toy enterprises that integrates “brand display, product trading and new product release” to promote the high-quality development of Chenghai toy industry and realize the blueprint and vision of “Chenghai toys going to the world”.</div>
            </div>
        </div>
        <div class="introduction-pic-block">
            <img :src="require('@/assets/img/introduction/intro-pic1.png')" alt="" class="introduction-pic">
            <img :src="require('@/assets/img/introduction/intro-pic2.png')" alt="" class="introduction-pic">
        </div>
    </div>
</div>
</template>

<script>
export default {
  props: {
    lang: {
      type: String,
      defalut: 'zh'
    }
  }
}
</script>

<style lang="less" scoped>
.middle-title{
    width: 100%;
    padding-top: 40px;
    font-size: 30px;
    font-weight: bold;
    color: #333333;
    text-align: center;
}
.middle-subtitle{
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #999999;
    text-align: center;
}
.introduction-contain{
    position: relative;
}
.introduction-left{
    padding-top: 40px;
    padding-left: 30px;
    width: 906px;
    height: 548px;
    background-color: #B80F18;
    box-sizing: border-box;
}
.introduction-left-ch{
    padding-top: 30px;
    padding-left: 30px;
    width: 906px;
    height: 548px;
    background-color: #B80F18;
    box-sizing: border-box;
}
.introduction-title{
    font-size: 26px;
    font-weight: bold;
    line-height: 40px;
    color: #FFFFFF;
}
.introduction-title-ch{
  width: 690px;
  margin-bottom: 13px;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
}
.introduction-con{
    width: 706px;
    font-size: 18px;
    font-weight: 400;
    line-height: 38px;
    color: #FFFFFF;
    text-indent: 40px;
}
.introduction-con-ch{
    width: 706px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #FFFFFF;
    text-indent: 28px;
}
.introduction-pic-block{
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
}
.introduction-pic{
    width: 427px;
    display: block;
}
.introduction-pic:first-child{
    margin-bottom: 20px;;
}
</style>
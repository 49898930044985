<template>
  <div class="services-section">
    <div class="middle-title">同期活动</div>
    <div class="middle-subtitle">CONCURRENT EVENT</div>
    <div class="event-con">
        <div class="event-group">
            <img :src="lang == 'zh' ? img : img_en" alt="" :class="lang == 'zh' ? 'img' : 'img-ch'">
        </div>
    </div>
  </div>
</template>

<script>
import event from '@/assets/img/introduction/event.png'
import eventEn from '@/assets/img/introduction/event_en.png'

export default {
  props: {
    lang: {
      type:String,
      default: 'zh'
    }
  },
  data() {
    return {
      img: event,
      img_en: eventEn
    }
  }
}
</script>

<style lang="less" scoped>
.middle-title{
    width: 100%;
    padding-top: 52px;
    font-size: 30px;
    font-weight: bold;
    color: #333333;
    text-align: center;
}
.middle-subtitle{
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
    color: #999999;
    text-align: center;
}
.event-group{
  width: 100%;
}
.event-group img{
    display: block;
    width: 1187px;
    margin: 0 auto;
}
</style>
<template>
  <div class="services-section">
    <div class="middle-title">采购商服务</div>
    <div class="middle-subtitle">PURCHASER SERVICES</div>
    <div class="services-con">
        <img :src="lang == 'zh' ? img : img_en" alt="" :class="lang == 'zh' ? 'img' : 'img-ch'">
    </div>
  </div>
</template>

<script>
import service from '@/assets/img/introduction/service.png'
import serviceEn from '@/assets/img/introduction/service_en.png'

export default {
  props: {
    lang: {
      type:String,
      default: 'zh'
    }
  },
  data() {
    return {
      img: service,
      img_en: serviceEn
    }
  }
}
</script>

<style lang="less" scoped>
.middle-title{
    width: 100%;
    padding-top: 60px;
    font-size: 30px;
    font-weight: bold;
    color: #333333;
    text-align: center;
}
.middle-subtitle{
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #999999;
  text-align: center;
}
.services-con{
    width: 100%;
    margin: 0 auto;
}
.img{
  display: block;
  width: 1129px;
  margin: 0 auto;
}
.img-ch{
  display: block;
  width: 1114px;
  margin: 0 auto;
}
</style>